import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Select, Space, Spin, Steps, Typography, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import FileListItemRender from "./FileListItemRender";
import { ToastContext } from "toast-provider";
import useCheckFile from "hooks-sna/src/useCheckFile";
import ItemTable from "./table/ItemTable";
import { TranslateContext } from "translate-provider";
import { CsvType, ItemCsv, useCsvToJson } from "hookcsvtojson";
import { useBasicModal } from "sna-components";
import { useUploadCsv } from "hooks-sna";
import CsvDataViewer from './CsvDataViewer';

const { Text } = Typography;
const BASE_URL = process.env.REACT_APP_API_V2;

const { Dragger } = Upload;
const BASE_URL_API = process.env.REACT_APP_API_V1;

export default function UploadProduct() {
    const toast = useContext(ToastContext);
    const [form] = Form.useForm();
    const [csvUploaded, setCsvUploaded] = useState(false);
    const [newAlbumRequired, setNewAlbumRequired] = useState(true);
    const { t } = useContext(TranslateContext);
    const [step, setStep] = useState(0);
    const formData = new FormData();
    const [csvFileList, setCsvFileList] = useState([]);
    const [operation, setOperation] = useState(null);
    const SubmitButton = submitButtonComponent(csvUploaded, t);
    const [error, setError] = useState(null);
    const [onSubmit, cancelUploadHandler, uploadProgress, cancelUpload, loading, result] = useUploadCsv(toast, t, setError)
    const [selectedCsvModel, setSelectedCsvModel] = useState(null);
    const [jsonData, handleFileChange, customer, errors, resetErrors] = useCsvToJson(operation?.FIELDS, CsvType.ITEM);
    const [csvData, setCsvData] = useState([]);
    const [model, setModel] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false); 
    const fetchDataRef = useRef(null);

    const [shouldResetOnClose, setShouldResetOnClose] = useState(false);

    const mapItemCsvToConfigCsv = {
        [ItemCsv.IMP_TEXTILE.NAME]: 'textilePrint',
        [ItemCsv.DTOC.NAME]: 'btoCProduct',
        [ItemCsv.FULL.NAME]: 'fullModel'
    };

    useEffect(() => {
        if (result) {
            toast.success(result.message, 15);
            reinitFull();
            if (fetchDataRef.current) fetchDataRef.current.fetchData();
        }
    }, [result]);

    const reinitCsvOnly = () => {
        setCsvUploaded(false);
        setCsvFileList([]);
        setCsvData([]);
        setModel('');
        setSelectedCsvModel(null);
    }

    const reinitFull = () => {
        reinitCsvOnly();
        form.resetFields();
        setOperation(null);
        setStep(0);
    }

    const [open, close, modal] = useBasicModal(false, () => {
        if (shouldResetOnClose) reinitCsvOnly(); // Réinitialise uniquement si nécessaire
    });

    useEffect(() => {
        if (error) {
            // reinitFull();
            setShowErrorMessage(true);
            open()
        }
    }, [error])

    const [checkFileName, checkFileSize] = useCheckFile(t);
    // const itemRender = FileListItemRender(setCsvFileList, setCsvUploaded, setCsvData);

    useEffect(() => {
        if (jsonData) {
            setCsvData(jsonData);
        }
    }, [jsonData]);

    const handleCsvDataChange = (updatedData) => {
        setCsvData(updatedData);
    };

    const handleSelectChange = (value) => {
        setCsvFileList([]);
        setCsvData([]);

        // Mettre à jour le modèle sélectionné
        const selectedModel = mapItemCsvToConfigCsv[value];
        setSelectedCsvModel(value); 
        if (selectedModel) {
            setModel(selectedModel);
            const selectedOperation = Object.values(ItemCsv).find(item => item.NAME === value);
            setOperation(selectedOperation || null);
        } else {
            setModel('');
            setOperation(null);
        }
    };

    const itemRender = FileListItemRender({ 
        setFileList: setCsvFileList, 
        setUploaded: setCsvUploaded, 
        setCsvData: setCsvData 
    });


    //init steps
    const stepsItem = [
        {
            title: <p className='d-none d-lg-block d-sm-none'>{t('csvImport')}</p>,
            content: <>
                {/* {showErrorMessage  && error && <Text type="danger">{error.response?.data?.message ? error.response?.data?.message : "une erreur est survenue, veuillez contacter un administrateur"}</Text>} */}
                <Form.Item label={t('operation')} rules={[{ required: true, message: t('required') }]}>
                    <Select
                        placeholder="Select your model"
                        value={selectedCsvModel}
                        onChange={handleSelectChange}
                        options={Object.keys(ItemCsv).map((key) => ({
                            label: ItemCsv[key].NAME,
                            value: ItemCsv[key].NAME
                        }))}
                    />
                </Form.Item>
                {loading && <Spin size="large" />}
                {operation &&
                    <>
                        <Form.Item name="zip"  >
                            <Dragger
                                itemRender={(originNode, file, currFileList, setCsvData) => itemRender(originNode, file, currFileList, setCsvFileList, setCsvUploaded)}
                                showUploadList={csvUploaded}
                                fileList={csvFileList}
                                onChange={({ fileList }) => {
                                    setCsvFileList(fileList)
                                }}
                                beforeUpload={async (file) => {
                                    const isCSV = file.type === 'text/csv' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
                                    if (!isCSV) {
                                        //remove file
                                        // setCsvFileList([]);
                                        // setCsvData([])
                                        toast.error('fichier non conforme', 15);
                                        // setCsvUploaded(isCSV);
                                        // return false;
                                    }

                                    await handleFileChange(file)
                                    setCsvUploaded(isCSV);
                                    return isCSV;
                                }}
                                onRemove={(file) => {
                                    setCsvFileList(prev => prev.filter(f => f.uid !== file.uid));
                                    setCsvUploaded(false);
                                    setCsvData([]);
                                }}
                                defaultFileList={[]}
                                maxCount={1}
                                listType="picture"
                                multiple={false}
                                accept=".csv,.xls,.xlsx"
                            >
                                {/*<Button icon={<FileZipOutlined />}>{t('selectZIP')}</Button>*/}
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text"> {t('uploadCSV')} </p>
                                <div className='d-none d-lg-block d-sm-none'>
                                    <p className="ant-upload-hint">
                                    </p>
                                </div>
                            </Dragger>
                        </Form.Item>
                        <Form.Item>
                            <Space direction={"vertical"}>
                                <SubmitButton />
                                <a href={operation.CSV} download={operation.NAME.replace(" ", "") + 'ProductImportTemplate.xls'}>Download {operation.NAME} excel import template </a>
                            </Space>
                        </Form.Item>
                        {csvData.length > 0 && (
                            <div className="mt-4">
                                <h3>Visualisation des données</h3>
                                <p>Les champs peuvent êtres éditer</p>
                                <CsvDataViewer data={csvData} onDataChange={handleCsvDataChange} model={model} />
                            </div>
                        )}
                    </>
                }
            </>
        },
        {
            title: <p className='d-none d-lg-block d-sm-none'>{t('checkingData')}</p>,
            content: <>
            </>
        }
    ]


    const modalContent = () => {

        // if(!error) return <></>
        return (
            <div>
                <h3>{error?.message ? error?.message : t('error')}</h3>
                {(error && error.errors?.length > 0) && error.errors?.map((error, index) => {
                    return errorMessage(error)
                })}
                {(errors && errors.length > 0) && errors.map((error, index) => {
                    return errorMessage(error)
                })}
            </div>
        )
    }

    const errorMessage = (error) => {
        if (error.message) {
            return (
                <>
                    <Text type="danger">{error.message ? error.message : "une erreur est survenue, veuillez contacter un administrateur"}</Text> <br />
                    {(error.list && error.list.length > 0) && <div>
                        <ul>
                            {error.list.map((error, index) => {
                                return <li key={index}>- {error}</li>
                            })}
                        </ul>
                    </div>}
                </>
            )
        } else {
            return <><Text type="danger">{error}</Text> <br /> </>
        }
    }

    useEffect(() => {
        if (errors.length > 0) {
            open()
        }
    }, [errors])


    return (
        <div className="d-flex justify-content-center " >
            {modal(modalContent())}
            <Form
                form={form}
                onFinish={async () => {
                    const zipFile = csvFileList[0];

                    // Append the files to formData
                    formData.append('csv', jsonData);
                    await onSubmit(csvData, { customer: customer }, `${BASE_URL_API}/item`);
                    if (!error && error !== null) setStep(step + 1);
                }}
                layout="vertical"
                className="rounded-5 p-3 w-90"
            >
                <Steps
                    className='d-none d-sm-none d-md-flex d-lg-flex  m-0 '
                    size="small"
                    current={step}
                    items={stepsItem}
                />

                <div className="d-flex d-md-flex flex-column justify-content-center p-sm-0 ">
                    {stepsItem[step].content}
                </div>

                <div>
                    <ItemTable refFetchData={fetchDataRef} />
                </div>

            </Form>
        </div>

    );
};

function submitButtonComponent(fileUploaded, t) {
    return () => {
        const [submittable, setSubmittable] = useState(false);
        useEffect(() => {

            if (fileUploaded) {
                setSubmittable(true);
            } else {
                setSubmittable(false);
            }
        }, [fileUploaded]);

        return (
            <Button type="primary" htmlType="submit" disabled={!submittable}>
                {t('next')}
            </Button>
        );
    };
}