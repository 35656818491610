import React, {useContext, useEffect, useRef, useState} from "react";
import {ToastContext} from "toast-provider";
import {Button, Form, Space, Spin, Steps, Typography} from "antd";
import {TranslateContext} from "translate-provider";
import useCheckFile from "hooks-sna/src/useCheckFile";
import FileListItemRender from "import-article/src/FileListItemRender";
import {InboxOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import ImportOrderTable from "./src/ImportOrderTable";
import {useBasicModal} from "sna-components";
import {useUploadCsv} from "hooks-sna";
import {useCsvToJsonOrder} from "hookcsvtojson";
import CsvImportCde from "./template/FormatImportCde-v8.xlsx";
import CsvDataViewer from 'import-article/src/CsvDataViewer';

const {Text} = Typography;

const BASE_URL_API = process.env.REACT_APP_API_V1;
const BASE_URL_API2 = process.env.REACT_APP_API_V2;

export default function ImportOrder() {
    const toast = useContext(ToastContext);
    const formData = new FormData();
    const [form] = Form.useForm();
    const [csvUploaded, setCsvUploaded] = useState(false);
    const [step, setStep] = useState(0);
    const {t} = useContext(TranslateContext);
    const [error, setError] = useState(null);
    const [csvFileList, setCsvFileList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [jsonData, handleFileChange, customer, csvErrors, resetErrors] = useCsvToJsonOrder()
    const SubmitButton = submitButtonComponent(csvUploaded, t, csvErrors.length > 0);
    const [onSubmit, cancelUploadHandler, uploadProgress,cancelUpload, loading, result] = useUploadCsv(toast,t,setError)
    const fetchDataRef = useRef(null);

    const [open, close, modal] = useBasicModal(false, ()=>{
        resetErrors();
        setError(null)
    }, null, {width: '50%', title: t('error')})

    const modalContent = () => {
            return (
                <div>
                    <h3>{t('error')}</h3>
                    <ul>
                        {(csvErrors && csvErrors.length > 0) && csvErrors.map((err, index) => <li key={index}>{err}</li>)}
                        {error && <h4>{error.message}</h4>}
                        {error && error.errors && error.errors.length > 0 && error.errors.map((err, index) => <li key={index}>{err}</li>)}
                    </ul>
                </div>
            )
    }

    useEffect(() => {
        if((csvErrors && csvErrors?.length > 0) || error) {
            open();
        }
    }, [csvErrors, error])

    useEffect(() => {
        if (result) {
            toast.success(result.message, 15);
            reinitFull();
            if(fetchDataRef.current) fetchDataRef.current.fetchData();
        }
    }, [result]);

    const handleCsvDataChange = (updatedData) => {
        setCsvData(updatedData);
    };
     
    const reinitCsvOnly = () => {
        setCsvUploaded(false);
        setCsvFileList([]);
        setCsvData([]);
    }

    const reinitFull = () => {
        reinitCsvOnly();
        form.resetFields();
        setStep(0);
    }

    useEffect(() => {
        if (jsonData) {
            setCsvData(jsonData);
        }
    }, [jsonData]);

    const [checkFileName, checkFileSize] = useCheckFile(t);

    const itemRender = FileListItemRender({ 
        setFileList: setCsvFileList, 
        setUploaded: setCsvUploaded, 
        setCsvData: setCsvData 
    });

   // Fonction pour supprimer la clé 'uniqueKey' à tous les niveaux de l'objet
    function removeUniqueKey(obj) {
        if (Array.isArray(obj)) {
            return obj.map(item => removeUniqueKey(item));
        }
        // Si l'objet est un objet simple, on supprime la clé uniqueKey si elle existe
        if (obj !== null && typeof obj === 'object') {
            const cleanedObj = { ...obj }; 
            delete cleanedObj.uniqueKey; 

            Object.keys(cleanedObj).forEach(key => {
                cleanedObj[key] = removeUniqueKey(cleanedObj[key]);
            });
            return cleanedObj;
        }
        return obj; 
    } 

    //init steps
    const stepsItem =[
        {
            title: <p className='d-none d-lg-block d-sm-none'>{t('csvImport')}</p>,
            content: <>

                {error && <>
                            <Text type="danger">{error.response ? error.response?.data?.message : "une erreur est survenue, veuillez contacter un administrateur" }</Text>
                            <ul>
                                {error.response?.data?.errors && error.response?.data?.errors.map((err, index) => <li key={index}>{err}</li>)}
                            </ul>
                         </>
                }
                {loading ? <Spin size="large" />
                    :
                    <>
                        <Form.Item name="zip"  >
                            <Dragger
                                itemRender={(originNode, file, currFileList) => itemRender(originNode, file, currFileList, setCsvFileList, setCsvUploaded)}
                                showUploadList={csvUploaded}
                                fileList={csvFileList}
                                onChange={({ fileList }) => {
                                    setCsvFileList(fileList)
                                }}
                                beforeUpload={async (file) => {
                                    const isCSV = file.type === 'text/csv';

                                    if (!isCSV) {
                                        toast.error('fichier non conforme', 15);
                                        return false;
                                    }
                                    await handleFileChange(file)
                                    setCsvUploaded(isCSV);
                                    return isCSV;
                                }}
                                onRemove={file => {
                                    console.log("remove", file)
                                    setCsvFileList(prev => prev.filter(f => f.uid !== file.uid));
                                    setCsvUploaded(false);
                                    setError(null);
                                    resetErrors();
                                }}
                                defaultFileList={[]}
                                maxCount={1}
                                listType="picture"
                                multiple={false}
                                accept=".csv"
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text"> {t('uploadCSV')} </p>
                                <div className='d-none d-lg-block d-sm-none'>
                                    <p className="ant-upload-hint">
                                    </p>
                                </div>
                            </Dragger>
                        </Form.Item>
                        <Form.Item>
                            <Space direction={"vertical"}>
                                <SubmitButton/>
                                <a href={CsvImportCde} download="ModelImportCde">Download excel import template </a>
                            </Space>
                        </Form.Item>
                        {csvData.length > 0 &&  (
                            <div className="mt-4" style={{marginBottom: 20}}>
                                <h3>Visualisation des données</h3>
                                <p>Les champs peuvent êtres éditer</p>
                                <CsvDataViewer data={csvData} onDataChange={handleCsvDataChange} model="order" />
                            </div>
                        )}
                    </>
                }
            </>
        },
        {
            title: <p className='d-none d-lg-block d-sm-none'>{t('checkingData')}</p>,
            content: <>
            </>
        }
    ]
    return (
        <div className="d-flex justify-content-center flex-column" >
            {modal(modalContent())}
            <Form
                form={form}
                onFinish={async () => {
                    const zipFile = csvFileList[0];
                    const cleanedData = csvData.map(order => removeUniqueKey(order));
                    // Convertion intermediateCommissionExclTax en nombre
                    const finalData = cleanedData.map(order => {
                        if (order.intermediateCommissionExclTax) {
                            order.intermediateCommissionExclTax = parseFloat(order.intermediateCommissionExclTax);
                        }
                        return order;
                    });
                    await onSubmit(finalData, { customer: customer }, `${BASE_URL_API}/order`);
                    if (!error && error !== null) setStep(step + 1);
                }}
                layout="vertical"
                className="rounded-5 p-3 w-90"
            >
                <Steps
                    className='d-none d-sm-none d-md-flex d-lg-flex  m-0 '
                    size="small"
                    current={step}
                    items={stepsItem}
                />
                <div className="d-flex d-md-flex flex-column justify-content-center p-sm-0 ">
                    {stepsItem[step].content}
                </div>
                <div>
                    <ImportOrderTable refFetchData={fetchDataRef} />
                </div>
            </Form> 
        </div>
    );
};

function submitButtonComponent(fileUploaded, t, error) {
    return () => {
        const [submittable, setSubmittable] = useState(false);
        useEffect(() => {
            if (fileUploaded && !error) {
                console.log("no error")
                setSubmittable(true);
            } else {
                console.log("error")
                setSubmittable(false);
            }
        }, [fileUploaded, error]);
        return (
            <Button type="primary" htmlType="submit" disabled={!submittable}>
                suivant
            </Button>
        );
    };
}