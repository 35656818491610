import { useCallback, useState } from "react";
import csvtojson from "csvtojson";
import * as XLSX from "xlsx"; // Ajout de la bibliothèque XLSX
import { ControlOperation } from "./ControlOperation";
import {ProductDtoCCsv,ProductFullCsv,ProductImpTextileCsv} from "./dto/productCsvDto";
// @ts-ignore
import FullCsvTemplate from "./templates/ModeleImportEC-v7.xls";
// @ts-ignore
import TextilePrintCsv from "./templates/modelImport_textil_print.xls";
// @ts-ignore
import ModeleImportDtoC from "./templates/ModeleImportDtoC.xls";

// Enum pour le type de CSV
export const CsvType = {
  ITEM: "ITEM",
  ORDER: "ORDER",
};

export const ItemCsv = {
  IMP_TEXTILE: {
    NAME: "Textile Print",
    FIELDS: ProductImpTextileCsv,
    CSV: TextilePrintCsv,
  },
  DTOC: {
    NAME: "BtoC Product",
    FIELDS: ProductDtoCCsv,
    CSV: ModeleImportDtoC,
  },
  FULL: {
    NAME: "Full CSV",
    FIELDS: ProductFullCsv,
    CSV: FullCsvTemplate,
  },
};

export interface errorType {
  message: string;
  list?: string[];
}

export default function useCsvToJson(fields: any, config: any) {
  const [jsonData, setJsonData] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [errors, setErrors] = useState<errorType[]>([]);


  
  const normalizeJsonHeaders = (json: Array<any>) => {
    const headers = Object.keys(json[0]).map(normalizeHeader);
    return json.map((item) => {
      return headers.reduce((acc, header, index) => {
        acc[header] = item[Object.keys(item)[index]];
        return acc;
      }, {});
    });
  };

  const handleCsvFile = async (result: string) => {
    let text = result.replace(/é/g, "e").replace(/è/g, "e").replace(/Ž/g, "e");
    return await csvtojson({ delimiter: ";" }).fromString(text);
  };
  
  const handleExcelFile = async (result: ArrayBuffer) => {
    const data = new Uint8Array(result);
    const workbook = XLSX.read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
  
    const json = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
  
    return normalizeJsonHeaders(json);
  };
  
  const validateColumns = (json: Array<any>) => {
    const csvHeaders = Object.keys(json[0]);
    const expectedHeaders = Object.keys(fields);
  
    const missingColumns = expectedHeaders.filter(header => !csvHeaders.includes(header));
    const extraColumns = csvHeaders.filter(header => !expectedHeaders.includes(header));
  
    if (missingColumns.length > 0 || extraColumns.length > 0) {
      throw new Error("Le fichier ne correspond pas au modèle sélectionné.");
    }
  };
  

  const handleFileChange = useCallback(
    async (file: File) => {

      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      const reader = new FileReader();
  
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result;
        let json;
  
        try {
          if (fileExtension === "csv") {
            json = await handleCsvFile(result as string);
          } else if (fileExtension === "xlsx" || fileExtension === "xls") {
            json = await handleExcelFile(result as ArrayBuffer);
          } else {
            throw new Error("Type de fichier non pris en charge");
          }
  
          setCustomer(json[0]?.CptClient?.trim());
  
          validateColumns(json);
  
          switch (config) {
            case CsvType.ITEM:
              processItemCsv(json, fields, setErrors, setJsonData);
              break;
            case CsvType.ORDER:
              break;
            default:
              throw new Error("Type de fichier CSV non reconnu");
          }
        } catch (error) {
          setErrors([{ message: error.message }]);
        }
      };
  
      if (fileExtension === "csv") {
        reader.readAsText(file, "ISO-8859-1");
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        reader.readAsArrayBuffer(file);
      }
    },
    [fields, config]
  );
  

  const resetErrors = () => {
    setErrors([]);
  };

  return [jsonData, handleFileChange, customer, errors, resetErrors];
}

const normalizeHeader = (header) => {
  return header
    .replace(/\s+/g, "")
    .replace(/é/g, "e")
    .replace(/è/g, "e");
};



function processItemCsv(json, fields, setErrors, setJsonData) {
  const operation = new ControlOperation(json, fields, "RefProduit");
  const mappedJson = operation.mappedToJson();

  mappedJson.forEach(item => {
    item.custodyCode = String(item.custodyCode);
    item.visualReferenceA = String(item.visualReferenceA  || "");
    item.visualReferenceB = String(item.visualReferenceB  || "");
    item.visualReferenceC = String(item.visualReferenceC  || "");
    item.visualReferenceD = String(item.visualReferenceD  || "");
    item.barCode = String(item.barCode  || "");
    item.occBarCode = String(item.occBarCode  || "");
    item.CodeEAN13DeReference = String(item.CodeEAN13DeReference  || "");
  });

  const isValid = checkError(operation, setErrors);
  if (isValid) setJsonData(mappedJson);
}


function checkError(operation: any, setErrors: any) {
  const operationErrors = operation.getErrors();
  if (operationErrors.length > 0) {
    setErrors(operationErrors);
  } else {
    setErrors([]); 
  }
  return operationErrors.length === 0;
}
