import {useCallback, useState} from "react";
import csvtojson from "csvtojson";


export class expectedKeys {

    static CptClient = "CptClient";
    static RefProduit = "sku";
    static TypeProduit = "Type";
    static Titre = "Titre";
    static Artiste = "Artiste";
    static DateCommercialisation = "DateCommercialisation";
    static Remarque = "Remarque";
    static LienUrlVisuelArticle = "LienUrlVisuelArticle";

}

export class expectedKeyFull extends expectedKeys{
  static CodeEAN13 = "CodeEAN13";
    static Boitage = "Boitage";
    static NbrPage = "NbrPage";
    static PrdFini = "PrdFini";
}


export default function useCsvToJsonProduct() {
  const [jsonData, setJsonData] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [isNotValid, setIsNotValid] = useState(false);
  const [invalidColumns, setInvalidColumns] = useState([]);
  const [missingColumns, setMissingColumns] = useState([]);


  const resetValues = () => {
    setJsonData([]);
    setCustomer(null);
    setIsNotValid(false);
    setInvalidColumns([]);
    setMissingColumns([]);
  };

  const handleFileChange = useCallback(async (file) => {
    const reader = new FileReader();



    reader.onload = async (e) => {
      let text = e.target.result;

      // Remplacer tous les 'é' par 'e'
      text = text.replace(/é/g, "e");
      text = text.replace(/è/g, "e");
      text = text.replace(/Ž/g, "e");

      // Convertir le texte CSV en JSON
      const json = await csvtojson({ delimiter: ";" }).fromString(text);

      // Vérifier la correspondance des clés
      const expectedKeys = [
        "CptClient",
        "RefProduit",
        "TypeProduit",
        "Titre",
        "Artiste",
        "DateCommercialisation",
        "Remarque",
        "LienUrlVisuelArticle",
        "CodeEAN13",
        "Boitage",
        "NbrPage",
        "PrdFini",
        "DescriptifPrdFini",
        "NbrCD",
        "NbrBD",
        "NbrDVD",
        "NbrVinyle",
        "Poids",
        "Hauteur",
        "Largeur",
        "Longueur",
        "Couleur",
        "Taille",
        "Genre",
        "CodeDouane",
        "LibellePrCN23",
        "StockSupportVierge",
        "RefSupportVierge",
        "OperationVisuelA",
        "RefVisuelA",
        "ImprFonceeVisuelA",
        "LienUrlPreviewVisuelA",
        "OperationVisuelB",
        "RefVisuelB",
        "ImprFonceeVisuelB",
        "LienUrlPreviewVisuelB",
        "OperationVisuelC",
        "RefVisuelC",
        "ImprFonceeVisuelC",
        "LienUrlPreviewVisuelC",
        "OperationVisuelD",
        "RefVisuelD",
        "ImprFonceeVisuelD",
        "LienUrlPreviewVisuelD",
        "OCC",
        "OccCodeEAN13Specifique",
        "DtoC",
        "Prix",
        "PrixPrestationSNA",
        "SupportVierge",
        "CptClientIntermediaire",
        "AnalytiqueClient2",
        "AnalytiqueClient1",
        "CodeEAN13DeReference"
      ];

      const invalidColumnsList = json.reduce((invalidColumnsAcc, item, index) => {
        const keys = Object.keys(item);
        const invalidKeys = keys.filter(key => !expectedKeys.includes(key));
        if (invalidKeys.length > 0) {
          invalidColumnsAcc.push({ index: index + 1, columns: invalidKeys });
        }
        return invalidColumnsAcc;
      }, []);

      setInvalidColumns(invalidColumnsList);

      const missingColumnsList = expectedKeys.filter(key => !Object.keys(json[0]).includes(key));
      setMissingColumns(missingColumnsList);

      const newIsValid = invalidColumnsList.length === 0 && missingColumnsList.length === 0;

      setIsNotValid(newIsValid); // Mettre à jour la valeur de isNotValidLight

      if (!newIsValid) {
        let errorMessage = "";
        if (invalidColumnsList.length > 0) {
          setIsNotValid(true)
          errorMessage += "Les colonnes suivantes du fichier CSV ne correspondent pas à la structure JSON attendue : " + invalidColumnsList.map(invalid => ` ${invalid.columns.join(', ')}`).join('; ') + "; ";
        }
        if (missingColumnsList.length > 0) {
          setIsNotValid(true)
          errorMessage += "Les colonnes suivantes sont manquantes dans le fichier CSV : " + missingColumnsList.join(', ');
        }
        console.error(errorMessage);
        return; // Arrêter le traitement si les colonnes ne correspondent pas ou sont manquantes
      }

      // Définir le client avec la première ligne
      setCustomer(json[0]?.CptClient || null);

      // Mapper les données JSON selon la structure attendue
      const mappedJson = json.map((item) => ({
        account: item.CptClient?.trim(),
        sku: item.RefProduit?.trim(),
        productType: item.TypeProduit?.trim(),
        title: item.Titre?.trim() || "",
        artist: item.Artiste?.trim() || "",
        description: "",
        itemClassification: "",
        marketingDate: item.DateCommercialisation ? item.DateCommercialisation.split("/").reverse()?.join("-") : "1900-01-01",
        note: item.Remarque?.trim() || "",
        imageUrl: item.LienUrlVisuelArticle || "",
        barCode: item.CodeEAN13?.trim() || "",
        numberOfItemsPerBox: Number(item.Boitage.replace(",", ".")) || 0,
        numberOfPages: Number(item.NbrPage.replace(",", ".")) || 0,
        finishedProduct: item.PrdFini !== null ? item.PrdFini.trim() === "oui" : true,
        finishedProductComposition: item.DescriptifPrdFini?.trim() || "",
        numberOfCD: Number(item.NbrCD.replace(",", ".")) || 0,
        numberOfBD: Number(item.NbrBD.replace(",", ".")) || 0,
        numberOfDVD: Number(item.NbrDVD.replace(",", ".")) || 0,
        numberOfVinyls: Number(item.NbrVinyle.replace(",", ".")) || 0,
        weight: Number(item.Poids.replace(",", ".")) || 0,
        height: Number(item.Hauteur.replace(",", ".")) || 0,
        width: Number(item.Largeur.replace(",", ".")) || 0,
        length: Number(item.Longueur.replace(",", ".")) || 0,
        color: item.Couleur?.trim() || "",
        size: item.Taille?.trim() || "",
        gender: item.Genre?.trim() || "",
        custodyCode: item.CodeDouane?.trim(),
        CN23Label: item.LibellePrCN23?.trim(),
        blankMediaStock: item.StockSupportVierge?.trim() || "",
        blankMediaRef: item.RefSupportVierge?.trim() || "",
        visualOperationA: item.OperationVisuelA?.trim() || "",
        visualReferenceA: item.RefVisuelA?.trim() || "",
        visualDarkPrintA: item.ImprFonceeVisuelA?.trim() === "oui",
        visualPreviewUrlA: item.LienUrlPreviewVisuelA?.trim() || "",
        visualOperationB: item.OperationVisuelB?.trim() || "",
        visualReferenceB: item.RefVisuelB?.trim() || "",
        visualDarkPrintB: item.ImprFonceeVisualB?.trim() === "oui",
        visualPreviewUrlB: item.LienUrlPreviewVisuelB?.trim() || "",
        visualOperationC: item.OperationVisuelC?.trim() || "",
        visualReferenceC: item.RefVisuelC?.trim() || "",
        visualDarkPrintC: item.ImprFonceeVisualC?.trim() === "oui",
        visualPreviewUrlC: item.LienUrlPreviewVisuelC?.trim() || "",
        visualOperationD: item.OperationVisuelD?.trim() || "",
        visualReferenceD: item.RefVisuelD?.trim() || "",
        visualDarkPrintD: item.ImprFonceeVisualD?.trim() === "oui",
        visualPreviewUrlD: item.LienUrlPreviewVisuelD?.trim() || "",
        occ: item.OCC.trim() === "oui",
        occBarCode: item.OccCodeEAN13Specifique?.trim() || "",
        // DtoC: item.DtoC === "oui" ? true : item.DtoC !== "non",
        DtoC: item.DtoC !== null ? item.DtoC === "oui" ? true : item.DtoC !== "non" : true,
        unitPrice: Number(item.Prix.replace(",", ".")) || 0.0,
        SNABilling: Number(item.PrixPrestationSNA.replace(",", ".")) || 0.0,
        SupportVierge: item.SupportVierge?.trim() === "oui",
        CodeEAN13DeReference: item.CodeEAN13DeReference?.trim() || "",
        AnalytiqueClient1: item.AnalytiqueClient1?.trim() || "",
        AnalytiqueClient2: item.AnalytiqueClient2?.trim() || "",
        CptClientIntermediaire: item.CptClientIntermediaire?.trim() || "",
      }));

      setJsonData(mappedJson.filter((item) => item.sku !== ""));
      setIsNotValid(false)
    };
    reader.readAsText(file, "ISO-8859-1");
  }, []);

  return [jsonData, handleFileChange, customer, isNotValid, invalidColumns, missingColumns, resetValues, setJsonData];
}

