import React, {useEffect, useReducer} from 'react'
import {debounce} from "lodash";
import {AutoComplete, Typography} from "antd";
import {CredentialFetcher, useFetch} from "hooks-sna";
import useSWR from "swr";

const {Text} = Typography;

const BASE_URL = process.env.REACT_APP_API_V2;

function ClientSearch({label, name, form, defaultValue, setClient, onReset, cb}) {

    const initialFilter= {
        query : '',
    }

    const [filter, updateFilter] = useReducer(
        (filter, updates) => ({ ...filter, ...updates }),
        initialFilter
    );

    let useFetchParams = {
        url: `${BASE_URL}/user/client`,
        filter: filter,
    };

    // const [loading, error, data, hasMore, metadata, progress, fetchData]= useFetch(useFetchParams)

    const {data, error, isLoading:loading} = useSWR(`${BASE_URL}/user/client?query=${filter.query}`, CredentialFetcher);



    const debouncedUpdateFilter = debounce((query) => {
        updateFilter({ query: query === '' ? null : query });
    }, 500);

    // useEffect(() => {
    //     if(data.length === 0) fetchData();
    // }, [filter]);

    const onSubmit = (values) => {

    }

    const createOptions = (data) => {
        let options = [];
        if(!data) return options;
        data.map((item) => {
            options.push({
                value: item.CLICOD,
                label: item.CLICOD+ ' '+ item.CLINOM,
            });
        });
        return options;
    };
    const onSearch = (e) => {
        debouncedUpdateFilter(e)
    }
    const onClear = (e) => {

        updateFilter({ query: null });
        if(setClient) setClient("")
        if(onReset) onReset()
    }



   return  (    
       <AutoComplete
           loading={loading}
           style={{ width: 300 }}
           allowClear
           onSearch={onSearch}
           onClear={onClear}

           defaultValue={defaultValue}
           // onSelect={(e)=>{updateFilterDasboard({client: e ? e : null})}}
           onSelect={(e)=>{
               // updateFilter({client: e ? e : null})

               if(form) {
                   console.log("form", e)
                   form.setFieldValue(name, e)
               }
               if(setClient) setClient(e)

               if(cb) cb()

               }}
           filterOption={(inputValue, option) =>
               option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
           }
           options={createOptions(data)}


           placeholder="ID compte Client"
           // filterOption={(inputValue, option) =>
           //     option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
           // }
       />
    // </Form.Item>
)}

export default ClientSearch