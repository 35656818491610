import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Table, Input, InputNumber, Form, Switch  } from 'antd';
import { configCsv } from './configCsv';

export default function CsvDataViewer({ data, onDataChange, model }) {
    const [editableData, setEditableData] = useState(data.map((item, index) => ({ ...item, key: index })));

    useEffect(() => {
    }, [data]);

    const transformData = useCallback((data) => {
        if (model === 'order') {
            const transformedData = data.flatMap((order) => {
                const { orderLines = [], shipTo = {}, ...rest } = order;
    
                return orderLines.map((line, lineIndex) => {
                    // Structure de chaque ligne
                    const formattedLine = {
                        uniqueKey: line.uniqueKey,
                        itemQty: line.itemQty || 0,
                        itemRef: line.itemRef || '',
                        unitPrice: line.unitPrice || 0,
                        unitPriceVAT: line.unitPriceVAT || 0,
                        unitPriceVatRate: line.unitPriceVatRate || 0,
                    };
                    return {
                        ...rest, 
                        ...shipTo,
                        ...formattedLine, 
                        customerOrderNumber: order.customerOrderNumber,
                    };
                });
            });
    
            return transformedData;
        }
        return data;
    }, [model]);
    
    const transformedData = useMemo(() => transformData(editableData), [editableData, transformData]);

    const getDataIndex = useCallback((key) => {
        const { columnToDataMapping } = configCsv[model] || {};
        
        return columnToDataMapping[key] || key;
    }, [model]);

    const handleSave = useCallback(
        (row) => {
            const newData = [...editableData];
            const index = newData.findIndex((item) => item.key === row.key);
        
            if (index > -1) {
                const item = newData[index];
    
                // Mise à jour des orderLines
                if (item.orderLines && Array.isArray(item.orderLines)) {
                    const updatedOrderLines = item.orderLines.map((line) => {
                            if (line.uniqueKey === row.uniqueKey) {
                            const filteredLine = {
                                itemQty: row.itemQty || line.itemQty,
                                itemRef: row.itemRef || line.itemRef,
                                unitPrice: row.unitPrice || line.unitPrice,
                                unitPriceVAT: row.unitPriceVAT || line.unitPriceVAT,
                                unitPriceVatRate: row.unitPriceVatRate || line.unitPriceVatRate,
                            };
                            return { ...line, ...filteredLine }; 
                        }
                        return line; 
                    });
        
                    // Mise à jour des orderLines dans l'objet principal
                    item.orderLines = updatedOrderLines;
                }
    
                // Mise à jour des champs de shipTo si l'un d'entre eux est modifié
                if (row.ad1 || row.ad2 || row.ad3 || row.city || row.contactName || row.countryCode || row.deliveryInstruction || row.idPointRelay || row.mail || row.phoneNumber || row.postalCode || row.recepientName || row.stateCode) {
                    item.shipTo = {
                        ...item.shipTo, 
                        ad1: row.ad1 || item.shipTo.ad1,
                        ad2: row.ad2 || item.shipTo.ad2,
                        ad3: row.ad3 || item.shipTo.ad3,
                        city: row.city || item.shipTo.city,
                        contactName: row.contactName || item.shipTo.contactName,
                        countryCode: row.countryCode || item.shipTo.countryCode,
                        deliveryInstruction: row.deliveryInstruction || item.shipTo.deliveryInstruction,
                        idPointRelay: row.idPointRelay || item.shipTo.idPointRelay,
                        mail: row.mail || item.shipTo.mail,
                        phoneNumber: row.phoneNumber || item.shipTo.phoneNumber,
                        postalCode: row.postalCode || item.shipTo.postalCode,
                        recepientName: row.recepientName || item.shipTo.recepientName,
                        stateCode: row.stateCode || item.shipTo.stateCode,
                    };
                }
                // Mise à jour des autres champs de l'objet principal
                newData[index] = { ...item, ...row }; 
    
                setEditableData(newData);
    
                // Appel de onDataChange avec les données sans key
                if (onDataChange) {
                    onDataChange(newData.map(({ key, ...rest }) => rest));
                }
            }
        },
        [editableData, onDataChange]
    );
    
    const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
        const [editing, setEditing] = useState(false);
        const [form] = Form.useForm();

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                    <Form form={form} component={false}>
                    {typeof record[dataIndex] === 'boolean' ? (
                        <Form.Item
                            style={{ margin: 0 }}
                            name={dataIndex}
                            valuePropName="checked"
                            initialValue={record[dataIndex]} 
                        >
                        <Switch 
                            onChange={(checked) => {
                            form.setFieldsValue({ [dataIndex]: checked });
                            save(); 
                            }} 
                        />
                        </Form.Item>
                    ) : typeof record[dataIndex] === 'number' ? (
                        <Form.Item
                            style={{ margin: 0 }}
                            name={dataIndex}
                            initialValue={record[dataIndex]} 
                        >
                            <InputNumber onPressEnter={() => save()} onBlur={() => save()} />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            style={{ margin: 0 }}
                            name={dataIndex}
                            initialValue={record[dataIndex]} 
                        >
                            <Input onPressEnter={() => save()} onBlur={() => save()} />
                        </Form.Item>
                    )}
                </Form>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={toggleEdit}
                >
                    {typeof record[dataIndex] === 'boolean' ? (
                       <Switch
                       checked={record[dataIndex]}
                       onChange={(checked) => {
                           handleSave({ ...record, [dataIndex]: checked });
                       }} 
                   />
                    ) : (
                        children
                    )}
                </div>
            );
        }
    
        return <td {...restProps}>{childNode}</td>;
    };

    const mergedColumns = useMemo(() => {
        if (!transformedData?.length || !model) return [];
        const { columnOrder } = configCsv[model] || {};
        if (!columnOrder) return [];
    
        return columnOrder.map((key, index) => ({
            title: key,
            dataIndex: getDataIndex(key),
            key,
            fixed: index < 4 ? 'left' : undefined, 
            editable: true,
            render: (text, record) => text || '-', 
        }));
    }, [transformedData, model, getDataIndex]);
    
    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const mappedColumns = mergedColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <div style={{ maxWidth: '100%' }}>
            <Table
                components={components}
                columns={mappedColumns}
                size="large"
                dataSource={transformedData}
                rowKey="key" 
                pagination={false}
                scroll={{ x: 'max-content' }}
                className="custom-csv-table"
            />
        </div>
    );
}