import { useCallback, useState } from "react";
import csvtojson from "csvtojson";

export default function useCsvToJsonOrder() {
  const [jsonData, setJsonData] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState([]);

  const handleFileChange = useCallback(async (file) => {
    const reader = new FileReader();

    const checkUTF8 = (text, defaultValue, lineReference) => {
      if (!text) return defaultValue;

      // Create a regular expression that matches unauthorized special characters.
      // Assuming authorized characters include alphanumeric characters, spaces, common punctuation,
      // characters used in email addresses, specific characters like apostrophes and parentheses, and accented characters.
      const authorizedCharsRegex = /^[a-zA-Z0-9 .,'@_\-()\/À-ÖØ-öø-ÿ’:]+$/;

      if (!authorizedCharsRegex.test(text)) {
        const errorMessage = `${lineReference} : The text "${text}" contains unauthorized special characters.`;
        setError((prev) => [...prev, errorMessage]);

        // Optionally, you can handle unauthorized characters (e.g., remove or replace them).
        text = text.replace(/[^a-zA-Z0-9 .,'@_\-()\/À-ÖØ-öø-ÿ’]/g, '');
      }
      return text;
    }
    reader.onload = async (e) => {
      let text = e.target.result;

      //replace all 'é' with 'e'
      text = text.replace(/é/g, "e");
      text = text.replace(/è/g, "e");
      text = text.replace(/Ž/g, "e");

      let json = await csvtojson({ delimiter: ";" }).fromString(text);

      //set customer from the first line
      setCustomer(json[0].CptClient?.trim());

      //remove all lines with empty RefCde
      const removeEmptyOrderNumbers = json.filter((item) => item.RefCde?.trim() !== "");

      //get distinct order numbers
      const distinctOrderNumbers = removeEmptyOrderNumbers.filter((item, index, self) =>
        index === self.findIndex((t) => (t.RefCde?.trim() === item.RefCde?.trim()))
      );

      let orderlines = {};
      let mappedJson;

      try {
        for (let item of json) {
          const lines = removeEmptyOrderNumbers.filter((line) => line.RefCde === item.RefCde).map((line, idx) => ({
            itemRef: checkUTF8(line.RefProduit?.trim(), ""),
            itemQty: Number(line.Quantite),
            unitPrice: line.PrixUnitaireHT ? Number(line.PrixUnitaireHT.replace(",", ".")) : null,
            unitPriceVAT:Number(line.PrixUnitaireTVA?.replace(",", ".") || 0.0),
            unitPriceVatRate: Number(line.PrixUnitaireTauxTVA?.replace(",", ".") || 0.0),
            uniqueKey: `${item.RefCde}-${idx}-${line.RefProduit?.trim()}`
          }));
          // Remove all other RefCde
          json = json.filter((line) => line.RefCde !== item.RefCde);

          // Use RefCde as key in the orderlines object
          orderlines[item.RefCde] = lines;
        }

        mappedJson = distinctOrderNumbers.map((item, index) => {
          const r = item.RefCde?.trim();

          return ({
            CptClient: checkUTF8(item.CptClient?.trim(), "", r),
            customerOrderNumber: checkUTF8(item.RefCde?.trim(), "", r),
            orderLabel: checkUTF8(item.LibelleCde?.trim(), "", r),
            orderDate: item.DateCde ? item.DateCde?.split("/").reverse().join("-") : "1900-01-01",
            currency: checkUTF8(item.Devise?.trim(), "", r),
            carrierServiceCode: checkUTF8(item.CodeServiceTransporteur?.trim(), "", r),
            doNotShipBefore: item.NePasExpedierAvantLe ? item.NePasExpedierAvantLe?.split("/").reverse().join("-") : "1900-01-01",
            corporate: item.EstParticulier === "1" ? true : false,
            shippingCost: item.FraisExpeHT ? Number(item.FraisExpeHT?.replace(",", ".")) : 0.0,
            shippingCostVat: item.FraisExpeTVA ? Number(item.FraisExpeTVA?.replace(",", ".")) : 0.0,
            shippingCostVatRate: item.FraisExpeTauxTVA ? Number(item.FraisExpeTauxTVA?.replace(",", ".")) : 0.0,
            totalAmountForItemsExclVAT: item.MontantArticlesHT ? Number(item.MontantArticlesHT?.replace(",", ".")) : 0.0,
            vatAmountForItems: item.MontantArticlesTVA ? Number(item.MontantArticlesTVA?.replace(",", ".")) : 0.0,
            itemOwnerAccount: checkUTF8(item.IntermediaireCptClient?.trim(), "", r),
            platform: checkUTF8(item.Platform?.trim(), "", r),
            store: checkUTF8(item.Boutique?.trim(), "", r),
            intermediateCommissionExclTax: item.IntermediaireComHT ? Number(item.IntermediaireComHT.replace(",", ".")) : undefined,
            shipTo:
            {
              recepientName: checkUTF8(item.Destinataire?.trim(), "", r),
              contactName: checkUTF8(item.AttnNom ? item.AttnNom?.trim() : item.Destinataire ? item.Destinataire?.trim() : "", "", r),
              ad1: checkUTF8(item.Ad1?.trim(), "", r),
              ad2: checkUTF8(item.Ad2?.trim(), "", r),
              ad3: checkUTF8(item.Ad3?.trim(), "", r),
              postalCode: checkUTF8(item.CP?.trim(), "", r),
              city: checkUTF8(item.Ville?.trim(), "", r),
              stateCode: checkUTF8(item.CodeEtat?.trim(), "", r),
              countryCode: checkUTF8(item.CodePays?.trim(), "", r),
              mail: checkUTF8(item.Mail?.trim(), "", r),
              phoneNumber: checkUTF8(item.Tel?.trim(), "", r),
              deliveryInstruction: checkUTF8(item.Instructions?.trim(), "", r),
              idPointRelay: checkUTF8(item.CodePointRelais?.trim(), "", r),
            },
            orderLines: orderlines[item.RefCde],
          })
        });
        setJsonData(mappedJson.filter((item) => item.customerOrderNumber !== ""));
        console.log('json :', jsonData)
      } catch (error) {
        console.log(error);
        const errorMessage = `Error while mapping the data: ${error}`;
        setError((prev) => [...prev, errorMessage]);
      }

    };
    reader.readAsText(file, "ISO-8859-1");
  }, []);

  const setData = (item, defaultValue) => {
    return item || defaultValue;
  };

  const resetError = () => {
    setError([]);
  };

  return [jsonData, handleFileChange, customer, error, resetError];
}