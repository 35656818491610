import React from 'react';
import { Table } from 'antd';
import { ImageCustom } from "sna-components"

function StockPrintOnDemand({ data }) {
    console.log("Stock Print :",data)
    const columns = [
        { title: 'Ref Visuel A', dataIndex: 'R_fVisuelA', key: 'R_fVisuelA', width: 150 },
        { title: 'Ref Visuel B', dataIndex: 'R_fVisuelB', key: 'R_fVisuelB', width: 150 },
        { title: 'Ref Visuel C', dataIndex: 'R_fVisuelC', key: 'R_fVisuelC', width: 150 },
        { title: 'Ref Visuel D', dataIndex: 'R_fVisuelD', key: 'R_fVisuelD', width: 150 },
        { title: 'Opération Visuel A', dataIndex: 'Op_rationVisuelA', key: 'Op_rationVisuelA', width: 70 },
        { title: 'Opération Visuel B', dataIndex: 'Op_rationVisuelB', key: 'Op_rationVisuelB', width: 70 },
        { title: 'Opération Visuel C', dataIndex: 'Op_rationVisuelC', key: 'Op_rationVisuelC', width: 70 },
        { title: 'Opération Visuel D', dataIndex: 'Op_rationVisuelD', key: 'Op_rationVisuelD', width: 70 },

        {
            title: 'Preview Visuel A',
            dataIndex: 'LienUrlPreviewVisuelA',
            key: 'LienUrlPreviewVisuelA',
            width: 150,
            render: (url) => url ? <ImageCustom src={url} alt="Preview A" /> : null,
        },
        {
            title: 'Preview Visuel B',
            dataIndex: 'LienUrlPreviewVisuelB',
            key: 'LienUrlPreviewVisuelB',
            width: 150,
            render: (url) => url ? <ImageCustom src={url} alt="Preview B" /> : null,
        },
        {
            title: 'Preview Visuel C',
            dataIndex: 'LienUrlPreviewVisuelC',
            key: 'LienUrlPreviewVisuelC',
            width: 150,
            render: (url) => url ? <ImageCustom src={url} alt="Preview C" /> : null,
        },
        {
            title: 'Preview Visuel D',
            dataIndex: 'LienUrlPreviewVisuelD',
            key: 'LienUrlPreviewVisuelD',
            width: 150,
            render: (url) => url ? <ImageCustom src={url} alt="Preview D" /> : null,
        },
    ];

    const dataSource = [data];

    return (
        <div>
            <Table pagination={false}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 'max-content' }}
            />
        </div>
    );
}

export default StockPrintOnDemand;
