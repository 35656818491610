// Modèle de base avec les colonnes communes
const baseColumnToDataMapping = {
    "CptClient": "CptClient",
    "RefProduit": "sku",
    "TypeProduit": "productType",
    "Titre": "title",
    "Artiste": "artist",
};

const baseColumnOrder = [
    "CptClient",
    "RefProduit",
    "TypeProduit",
    "Titre",
    "Artiste",
];

// Configuration pour le modèle Textile Print
export const configCsv = {
    textilePrint: {
        columnToDataMapping: {
            ...baseColumnToDataMapping,
            "CodeEAN13DeReference": "CodeEAN13DeReference",
            "DateCommercialisation": "marketingDate",
            "CptClientIntermediaire" : "CptClientIntermediaire",
            "Poids": "weight",
            "Couleur": "color",
            "Taille": "size",
            "Genre": "gender",
            "SupportVierge": "SupportVierge",
            "Prix": "unitPrice",
            "CodeDouane": "custodyCode",
            "LibellePrCN23": "CN23Label",
            "AnalytiqueClient1": "AnalytiqueClient1",
            "AnalytiqueClient2": "AnalytiqueClient2",
            "RefSupportVierge": "blankMediaRef",
            "StockSupportVierge": "blankMediaStock",
            "PrixPrestationSNA": "SNABilling",
            "OperationVisuelA": "visualOperationA",
            "RefVisuelA": "visualReferenceA",
            "ImprFonceeVisuelA": "visualDarkPrintA",
            "LienUrlPreviewVisuelA": "visualPreviewUrlA",
            "OperationVisuelB": "visualOperationB",
            "RefVisuelB": "visualReferenceB",
            "ImprFonceeVisuelB": "visualDarkPrintB",
            "LienUrlPreviewVisuelB": "visualPreviewUrlB",
            "OperationVisuelC": "visualOperationC",
            "RefVisuelC": "visualReferenceC",
            "ImprFonceeVisuelC": "visualDarkPrintC",
            "LienUrlPreviewVisuelC": "visualPreviewUrlC",
            "OperationVisuelD": "visualOperationD",
            "RefVisuelD": "visualReferenceD",
            "ImprFonceeVisuelD": "visualDarkPrintD",
            "LienUrlPreviewVisuelD": "visualPreviewUrlD"
        },
        columnOrder: [
            ...baseColumnOrder,
            "CodeEAN13DeReference",
            "DateCommercialisation",
            "CptClientIntermediaire",
            "Poids",
            "Couleur",
            "Taille",
            "Genre",
            "SupportVierge",
            "Prix",
            "CodeDouane",
            "LibellePrCN23",
            "AnalytiqueClient1",
            "AnalytiqueClient2",
            "RefSupportVierge",
            "StockSupportVierge",
            "PrixPrestationSNA",
            "OperationVisuelA",
            "RefVisuelA",
            "ImprFonceeVisuelA",
            "LienUrlPreviewVisuelA",
            "OperationVisuelB",
            "RefVisuelB",
            "ImprFonceeVisuelB",
            "LienUrlPreviewVisuelB",
            "OperationVisuelC",
            "RefVisuelC",
            "ImprFonceeVisuelC",
            "LienUrlPreviewVisuelC",
            "OperationVisuelD",
            "RefVisuelD",
            "ImprFonceeVisuelD",
            "LienUrlPreviewVisuelD"
        ]
    },

    // Configuration pour le modèle BtoC Product
    btoCProduct: {
        columnToDataMapping: {
            ...baseColumnToDataMapping,
            "Remarque": "Remarque",
            "LienUrlVisuelArticle": "imageUrl",
            "CodeEAN13": "barCode",
            "CodeEAN13DeReference": "CodeEAN13DeReference",
            "OCC": "occ",
            "OccCodeEAN13Specifique": "occBarCode",
            "DateCommercialisation": "marketingDate",
            "Boitage": "numberOfItemsPerBox",
            "NbrPage": "numberOfPages",
            "DtoC": "DtoC",
            "PrdFini": "finishedProduct",
            "DescriptifPrdFini": "finishedProductComposition",
            "CptClientIntermediaire" : "CptClientIntermediaire",
            "Poids": "weight",
            "Hauteur": "height",
            "Largeur": "width",
            "Longueur": "length",
            "NbrCD": "numberOfCD",
            "NbrDVD": "numberOfDVD",
            "NbrBD": "numberOfBD",
            "NbrVinyle": "numberOfVinyls",
            "Couleur": "color",
            "Taille": "size",
            "Genre": "gender",
            "SupportVierge": "SupportVierge",
            "Prix": "unitPrice",
            "CodeDouane": "custodyCode",
            "LibellePrCN23": "CN23Label",
            "AnalytiqueClient1": "AnalytiqueClient1",
            "AnalytiqueClient2": "AnalytiqueClient2",
            "PrixPrestationSNA": "SNABilling",
        },
        columnOrder: [
            ...baseColumnOrder,
            "Remarque",
            "LienUrlVisuelArticle",
            "CodeEAN13",
            "CodeEAN13DeReference",
            "OCC",
            "OccCodeEAN13Specifique",
            "DateCommercialisation",
            "Boitage",
            "NbrPage",
            "DtoC",
            "PrdFini",
            "DescriptifPrdFini",
            "CptClientIntermediaire",
            "Poids",
            "Hauteur",
            "Largeur",
            "Longueur",
            "NbrCD",
            "NbrDVD",
            "NbrBD",
            "NbrVinyle",
            "Couleur",
            "Taille",
            "Genre",
            "SupportVierge",
            "Prix",
            "CodeDouane",
            "LibellePrCN23",
            "AnalytiqueClient1",
            "AnalytiqueClient2",
            "PrixPrestationSNA"
        ]
    },

     // Configuration pour le modèle Full 
    fullModel: {
        columnToDataMapping: {
            ...baseColumnToDataMapping,
            "Remarque": "Remarque",
            "LienUrlVisuelArticle": "imageUrl",
            "CodeEAN13": "barCode",
            "CodeEAN13DeReference": "CodeEAN13DeReference",
            "OCC": "occ",
            "OccCodeEAN13Specifique": "occBarCode",
            "DateCommercialisation": "marketingDate",
            "Boitage": "numberOfItemsPerBox",
            "NbrPage": "numberOfPages",
            "DtoC": "DtoC",
            "PrdFini": "finishedProduct",
            "DescriptifPrdFini": "finishedProductComposition",
            "CptClientIntermediaire" : "CptClientIntermediaire",
            "Poids": "weight",
            "Hauteur": "height",
            "Largeur": "width",
            "Longueur": "length",
            "NbrCD": "numberOfCD",
            "NbrDVD": "numberOfDVD",
            "NbrBD": "numberOfBD",
            "NbrVinyle": "numberOfVinyls",
            "Couleur": "color",
            "Taille": "size",
            "Genre": "gender",
            "SupportVierge": "SupportVierge",
            "Prix": "unitPrice",
            "CodeDouane": "custodyCode",
            "LibellePrCN23": "CN23Label",
            "AnalytiqueClient1": "AnalytiqueClient1",
            "AnalytiqueClient2": "AnalytiqueClient2",
            "RefSupportVierge": "blankMediaRef",
            "StockSupportVierge": "blankMediaStock",
            "PrixPrestationSNA": "SNABilling",
            "OperationVisuelA": "visualOperationA",
            "RefVisuelA": "visualReferenceA",
            "ImprFonceeVisuelA": "visualDarkPrintA",
            "LienUrlPreviewVisuelA": "visualPreviewUrlA",
            "OperationVisuelB": "visualOperationB",
            "RefVisuelB": "visualReferenceB",
            "ImprFonceeVisuelB": "visualDarkPrintB",
            "LienUrlPreviewVisuelB": "visualPreviewUrlB",
            "OperationVisuelC": "visualOperationC",
            "RefVisuelC": "visualReferenceC",
            "ImprFonceeVisuelC": "visualDarkPrintC",
            "LienUrlPreviewVisuelC": "visualPreviewUrlC",
            "OperationVisuelD": "visualOperationD",
            "RefVisuelD": "visualReferenceD",
            "ImprFonceeVisuelD": "visualDarkPrintD",
            "LienUrlPreviewVisuelD": "visualPreviewUrlD",
        },
        columnOrder: [
            ...baseColumnOrder,
            "Remarque",
            "LienUrlVisuelArticle",
            "CodeEAN13",
            "CodeEAN13DeReference",
            "OCC",
            "OccCodeEAN13Specifique",
            "DateCommercialisation",
            "Boitage",
            "NbrPage",
            "DtoC",
            "PrdFini",
            "DescriptifPrdFini",
            "CptClientIntermediaire",
            "Poids",
            "Hauteur",
            "Largeur",
            "Longueur",
            "NbrCD",
            "NbrDVD",
            "NbrBD",
            "NbrVinyle",
            "Couleur",
            "Taille",
            "Genre",
            "SupportVierge",
            "Prix",
            "CodeDouane",
            "LibellePrCN23",
            "AnalytiqueClient1",
            "AnalytiqueClient2",
            "RefSupportVierge",
            "StockSupportVierge",
            "PrixPrestationSNA",
            "OperationVisuelA",
            "RefVisuelA",
            "ImprFonceeVisuelA",
            "LienUrlPreviewVisuelA",
            "OperationVisuelB",
            "RefVisuelB",
            "ImprFonceeVisuelB",
            "LienUrlPreviewVisuelB",
            "OperationVisuelC",
            "RefVisuelC",
            "ImprFonceeVisuelC",
            "LienUrlPreviewVisuelC",
            "OperationVisuelD",
            "RefVisuelD",
            "ImprFonceeVisuelD",
            "LienUrlPreviewVisuelD", 
        ]
    },
    order: {
        columnToDataMapping: {
            "CptClient": "CptClient",
            "RefCde": "customerOrderNumber",
            "LibelleCde": "orderLabel",
            "DateCde": "orderDate",
            "Devise": "currency",
            "CodeServiceTransporteur": "carrierServiceCode",
            "NePasExpedierAvantLe": "doNotShipBefore",
            "EstParticulier": "corporate",
            "FraisExpeHT": "shippingCost",
            "FraisExpeTVA": "shippingCostVat",
            "FraisExpeTauxTVA": "shippingCostVatRate",
            "MontantArticlesHT": "totalAmountForItemsExclVAT",
            "MontantArticlesTVA": "vatAmountForItems",
            "IntermediaireCptClient": "itemOwnerAccount",
            "Plateforme": "platform",
            "Boutique": "store",
            "IntermediaireComHT": "intermediateCommissionExclTax", 
            "RefProduit": "itemRef",
            "Quantite": "itemQty",
            "PrixUnitaireHT": "unitPrice",
            "PrixUnitaireTVA": "unitPriceVAT",
            "PrixUnitaireTauxTVA": "unitPriceVatRate",
            "Destinataire": "recepientName",
            "AttnNom": "contactName",
            "Ad1": "ad1",
            "Ad2": "ad2",
            "Ad3": "ad3",
            "CP": "postalCode",
            "Ville": "city",
            "CodeEtat": "stateCode", 
            "CodePays": "countryCode",
            "Mail": "mail",
            "Tel": "phoneNumber",
            "Instructions": "deliveryInstruction",
            "CodePointRelais": "idPointRelay",
        },
        columnOrder: [
            "CptClient",
            "RefCde",
            "LibelleCde", 
            "DateCde", 
            "Devise", 
            "CodeServiceTransporteur", 
            "NePasExpedierAvantLe",
            "EstParticulier",
            "FraisExpeHT",
            "FraisExpeTVA", 
            "FraisExpeTauxTVA", 
            "MontantArticlesHT", 
            "MontantArticlesTVA", 
            "IntermediaireCptClient", 
            "Plateforme",
            "Boutique",
            "IntermediaireComHT", 
            "RefProduit", 
            "Quantite",
            "PrixUnitaireHT", 
            "PrixUnitaireTVA", 
            "PrixUnitaireTauxTVA", 
            "Destinataire", 
            "AttnNom", 
            "Ad1", 
            "Ad2", 
            "Ad3", 
            "CP",
            "Ville", 
            "CodeEtat", 
            "CodePays", 
            "Mail", 
            "Tel", 
            "Instructions", 
            "CodePointRelais", 
        ]
    }
};
